@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@700&display=swap');

@font-face {
  font-family: gunplayrg;
  src: url("./gunplayrg.ttf");
}

.App {
  text-align: center;
}

.App-logo {

  pointer-events: none;
}


.App-header {

  background-color: #2f4353;
  background-image: linear-gradient(135deg, #2f4353 0%, #d2ccc4 74%);
  font-family: 'gunplayrg', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px + 2vmin);
  color: rgb(0, 0, 0);
}



#Logo-kopf {
  animation: App-logo-spin infinite 11s linear;
  transform-origin: center;

}

#hirschaugen-voll {
  fill: red;
  fill-opacity: 0;
  animation: Eye-color infinite 11s linear;
}

.Logo-schrift {}

.App-soon {
  margin-bottom: 50px;
  padding: 6px;


}

.App-contact {
  padding: 6px;
  border: 3px solid #000;
  animation: shake 5s linear infinite;
}

.App-mail {
  width: 300px;
}

.App-mail-active {
  padding: 6px;
  border: 3px solid #000;
  animation: shake 5s linear infinite;
  background-color: #2f4353;
  cursor: pointer;
  color: wheat;
}

.App-contact:hover {
  background-color: #2f4353;
  cursor: pointer;
  color: wheat;
}


@keyframes App-logo-spin {
  0% {
    fill: rgb(0, 0, 0);
  }

  88% {
    fill: #3d2b1f;
    transform: scale(100%);
  }

  89% {
    transform: scale(220%);
  }

  91% {
    transform: scale(220%);
  }

  92% {
    transform: scale(100%);
  }
}

@keyframes Schrift-ani {
  0% {
    fill: rgb(0, 0, 0);
  }

  50% {
    fill: rgb(85, 32, 2);
  }


}

@keyframes App-logo-shake {}

@keyframes Eye-color {
  0% {
    fill-opacity: 0
  }

  70% {
    fill-opacity: 0
  }

  90% {
    fill-opacity: 1
  }

  0% {
    fill-opacity: 0
  }
}


@keyframes shake {
  90% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  91% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  92% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  93% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  94% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  95% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  96% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  97% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  98% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  99% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}